import { Col, Flex, Row } from 'antd'
import { HelmetHeader } from '../../components/elements/HelmetHeader'
import { MyTeamsHeader } from '../../components/articles/MyTeamsHeader'
import { useUserPreferredTeams } from '../../hooks/useUserPreferredTeams'
import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import { useNavigate } from 'react-router-dom'
import { ArticlesWrapper } from '../../components/articles/ArticlesWrapper'
import Loader from '../../components/layouts/Loader'

export default function MyTeams() {
  const navigate = useNavigate()
  const [teamId, setTeamId] = useState()
  const [activeTabKey, setActiveTabKey] = useState('')
  const { data, isLoading, isRefetching } = useUserPreferredTeams()

  useEffect(() => {
    if (!isEmpty(data)) {
      const firstTeamId = data[0]._id
      if (data.length > 1) {
        setActiveTabKey(firstTeamId)
      }
      setTeamId(firstTeamId)
    }
  }, [data])

  useEffect(() => {
    if (isEmpty(data) && !isRefetching && !isLoading) navigate('/preferred-teams')
  }, [isRefetching, isLoading, navigate, data])

  const onTabChange = (key) => {
    setTeamId(key)
    setActiveTabKey(key)
  }

  if (isLoading || isEmpty(teamId)) return <Loader />

  return (
    <Row justify="center" className="bg bg4">
      <HelmetHeader />
      <Col flex="1300px">
        <Flex vertical gap={20}>
          <MyTeamsHeader activeTab={activeTabKey} teams={data} onTabChange={onTabChange} />
          <ArticlesWrapper
            pageKey="MyTeams"
            teamId={teamId}
            teamsData={data}
            initialBlogsData={{ pageSize: 12, teamIds: [teamId], pageKey: 'myTeams' }}
            useExclusive
          />
        </Flex>
      </Col>
    </Row>
  )
}
