import { Button, Flex, Layout, Typography } from 'antd'
import { useUser } from '../../hooks/useUser'
import { Link, useNavigate } from 'react-router-dom'

import { ChampionsLeagueHeaderLayout } from '../../components/layouts/ChampionsLeagueLiveLayout'
import { useResponsive } from '../../hooks/useResponsive'

export default function ChampionsLeaguePaymentFail() {
  const { user } = useUser()
  const navigate = useNavigate()
  const { isMobileView } = useResponsive()

  return (
    <Layout>
      <ChampionsLeagueHeaderLayout user={user} navigate={navigate} />
      <Layout.Header className="bg-champions-payment-fail" style={{ height: `${isMobileView ? '200px' : '340px'}` }} />
      <Layout.Content style={{ background: 'white' }}>
        <Flex vertical justify="center" align="center">
          <Typography.Title level={3} style={{ marginBottom: 10 }}>
            {'יש לנו בעיה טכנית!'}
          </Typography.Title>
          <Typography.Title level={3}>{'התשלום לא בוצע'}</Typography.Title>
          <Typography.Text>{'ניתן לנסות שנית מאוחר יותר'}</Typography.Text>
          <Link to={'/'}>
            <Button type="primary" style={{ borderRadius: '55px', padding: '25px 40px', marginTop: '40px' }}>
              {'חזרה לאזור האישי'}
            </Button>
          </Link>
        </Flex>
      </Layout.Content>
    </Layout>
  )
}
