import { Col, Typography, Card, Flex, Avatar, Divider, Badge, Button } from 'antd'
import LocationIcon from '../../assets/icons/location.svg'
import { Link } from 'react-router-dom'
import { useResponsive } from '../../hooks/useResponsive'

const getBackgroundColor = (purchased, disabled) => {
  if (purchased && disabled) return '#DAF2E4'
  if (purchased) return '#009640'
  return undefined
}
export const GameCard = ({ match, isPaymentPage = false }) => {
  const { isMobileView } = useResponsive()
  const price = new Intl.NumberFormat('he-IL', { style: 'currency', currency: 'ILS', maximumFractionDigits: 0 }).format(
    14,
  )
  const { _id, homeTeam, awayTeam, stadium, matchHour, matchStatus, linkAvailable, purchased } = match
  return (
    <Col
      style={{
        padding: 0,
        minWidth: '300px',
      }}
    >
      <Card
        title=""
        bordered={false}
        style={{
          borderRadius: '16px',
        }}
        styles={{
          body: {
            padding: isMobileView ? '24px 10px 0 0' : '32px 32px 0 0',
            height: 184,
            width: isMobileView ? '100%' : 460,
            position: 'relative',
          },
        }}
      >
        <Flex
          justify="space-between"
          style={{
            height: '100%',
          }}
        >
          <Flex
            vertical
            style={{
              width: '100%',
            }}
          >
            <Flex vertical gap={12}>
              <Flex gap={10} align="center">
                <Avatar size={34} shape="square" src={homeTeam.iconUrl} />
                <Typography.Text strong>{homeTeam.name}</Typography.Text>
              </Flex>
              <Flex gap={10} align="center">
                <Avatar size={34} shape="square" src={awayTeam.iconUrl} />
                <Typography.Text strong>{awayTeam.name}</Typography.Text>
              </Flex>
            </Flex>
          </Flex>
          <Flex>
            <Divider type="vertical" style={{ height: '100px', marginInline: '0' }} />
            <Flex vertical justify="space-between" gap={10} style={{ minWidth: isMobileView ? '130px' : '170px' }}>
              <Flex vertical gap={10} style={{ marginRight: isMobileView ? 5 : 20 }}>
                <Flex vertical>
                  <Flex gap={5} align="center">
                    <img src={LocationIcon} alt="Icon" style={{ width: '16px', height: '16px' }} />
                    <Typography.Text style={{ whiteSpace: 'nowrap' }}>{stadium.name}</Typography.Text>
                  </Flex>
                </Flex>
                <Flex vertical style={{ paddingRight: '4px' }}>
                  <Typography.Text strong>{matchHour}</Typography.Text>
                  <Badge color={matchStatus === 'טרם התחיל' ? 'yellow' : 'green'} text={matchStatus} />
                </Flex>
              </Flex>
              {!isPaymentPage && (
                <Link to={purchased ? `/champions-league/live/${_id}` : `/champions-league/payment?gameId=${_id}`}>
                  <Button
                    type="primary"
                    disabled={purchased && !linkAvailable}
                    style={{
                      background: getBackgroundColor(purchased, purchased && !linkAvailable),
                      borderRadius: '0px 16px',
                      color: purchased && !linkAvailable ? '#828080' : 'white',
                      width: '100%',
                    }}
                  >
                    {purchased ? 'לצפייה ישירה' : 'לרכישה'}
                  </Button>
                </Link>
              )}
            </Flex>
          </Flex>
        </Flex>
        <Flex
          justify="center"
          align="center"
          style={{
            position: 'absolute',
            width: `${isPaymentPage && '100%'}`,
            bottom: 0,
            height: '48px',
            marginRight: `${isPaymentPage && !isMobileView ? '-32px' : '0px'}`,
          }}
        >
          {isPaymentPage && (
            <Flex
              justify="center"
              style={{
                background: '#D2D6EE80',
                borderRadius: '0px 0px 12px 12px',
                width: '100%',
                height: '100%',
                alignItems: 'center',
              }}
            >
              <Typography.Title level={4} style={{ marginBottom: 0 }}>{`סה״כ לתשלום: ${price}`}</Typography.Title>
            </Flex>
          )}
          {!isPaymentPage && purchased && !linkAvailable && (
            <Flex justify="center" style={{ alignItems: 'center' }}>
              <Typography.Text style={{ fontSize: 12 }}>*הלינק יהיה פעיל 30 דקות לפני המשחק</Typography.Text>
            </Flex>
          )}
        </Flex>
      </Card>
    </Col>
  )
}
