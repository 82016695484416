import { Form, Input, Button, Select, Flex, Avatar } from 'antd'
import { useTeams } from '../../../../hooks/useTeams'
import Loader from '../../../../components/layouts/Loader'
import { CaretDownOutlined } from '@ant-design/icons'
import { useMemo } from 'react'

export const ChampionsTeamForm = ({ onSubmit, initialValues = {}, isLoading }) => {
  const [form] = Form.useForm()
  const { data, isLoading: isTeamsLoading } = useTeams()

  const optionsData = useMemo(
    () => data.map((team) => ({ value: team._id, label: team.name, iconUrl: team.iconUrl })),
    [data],
  )

  if (isTeamsLoading) return <Loader />
  return (
    <Form layout="vertical" form={form} onFinish={onSubmit} initialValues={initialValues}>
      <Form.Item hidden name="_id">
        <Input />
      </Form.Item>
      <Form.Item rules={[{ required: true }]} name="teams" label={'שם הקבוצה'}>
        <Select
          mode="tags"
          options={optionsData}
          suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none', color: 'black' }} />}
          optionRender={(option) => (
            <Flex justify="space-between" align="center">
              {option.data.label}
              <Avatar size={43} shape="square" src={option.data.iconUrl} />
            </Flex>
          )}
          showSearch
          optionFilterProp="label"
          placeholder="הוסף שם קבוצה"
        />
      </Form.Item>

      <Button block loading={isLoading} style={{ marginTop: 24 }} type="primary" htmlType="submit">
        {'שמירה'}
      </Button>
    </Form>
  )
}
