import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons'
import { Button, Flex } from 'antd'
import { Fragment } from 'react'
import { useResponsive } from '../../../hooks/useResponsive'

const mobileStyle = {
  overflowX: 'auto',
  flexDirection: 'row',
  position: 'sticky',
  top: '31vh',
  zIndex: 5,
  padding: '20px 10px',
  backgroundColor: 'white',
}

const desktopStyle = {
  position: 'sticky',
  top: 'calc(30vh - 64px)',
  zIndex: 5,
  padding: '20px 0',
  backgroundColor: 'white',
}

export default function VisibleDates({
  visibleDates,
  allDates,
  selectedDate,
  datesLength,
  handlePrevClick,
  handleDateClick,
  handleNextClick,
}) {
  const { isMobileView } = useResponsive()

  return (
    <Flex gap={8} style={isMobileView ? mobileStyle : desktopStyle}>
      {visibleDates.map((date) => (
        <Fragment key={date}>
          {visibleDates[0] === date && allDates[0] !== visibleDates[0] && !isMobileView && (
            <Button
              key={`${date}PrevArrow`}
              icon={<ArrowRightOutlined />}
              style={{ borderRadius: '20px', padding: '0 15px' }}
              onClick={handlePrevClick}
            />
          )}
          <Button
            key={date}
            type={selectedDate === date ? 'primary' : 'default'}
            style={{ borderRadius: '20px', padding: '0 15px' }}
            onClick={() => handleDateClick(date)}
          >
            {date}
          </Button>
          {visibleDates[visibleDates.length - 1] === date &&
            allDates[datesLength - 1] !== visibleDates[visibleDates.length - 1] &&
            !isMobileView && (
              <Button
                key={`${date}NextArrow`}
                icon={<ArrowLeftOutlined />}
                style={{ borderRadius: '20px', padding: '0 15px' }}
                onClick={handleNextClick}
              />
            )}
        </Fragment>
      ))}
    </Flex>
  )
}
