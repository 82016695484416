import React, { useState } from 'react'
import { createSearchParams, Link, Outlet, useNavigate } from 'react-router-dom'
import { Layout, Flex, Affix, Button, Typography, Row } from 'antd'
import { LogoutOutlined, UserOutlined } from '@ant-design/icons'
import { useUser } from '../../hooks/useUser'
import { useResponsive } from '../../hooks/useResponsive'
import MobileUserMenu from './MobileUserMenu'

const { Header, Content, Footer } = Layout

export default function ChampionsLeagueLayout() {
  const { user, logOut, isVerified } = useUser()
  const { isMobileView } = useResponsive()
  const navigate = useNavigate()
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen)
  }

  const handleUserClick = () => {
    if (!user) {
      navigate({
        pathname: '/login',
        search: createSearchParams({
          champions: '/champions-league',
        }).toString(),
      })
    }
  }

  return (
    <Layout>
      <Affix offsetTop={0}>
        <Header className="bg-champions-lobby" style={{ padding: isMobileView && '0 20px' }}>
          <Flex justify="space-between" style={{ height: isMobileView ? '60px' : '90px' }}>
            <Link to="/" style={{ alignContent: 'center' }}>
              <img
                src="/images/logo.svg"
                alt="logo"
                width="100%"
                onClick={() => navigate('/')}
                style={{ maxWidth: isMobileView ? 35 : 50, display: 'block', cursor: 'pointer' }}
              />
            </Link>
            {isMobileView ? (
              <Button
                shape="round"
                onClick={toggleDrawer}
                style={{
                  color: 'white',
                  marginTop: '10px',
                  background: 'unset',
                }}
              >
                {user?.firstName ? `שלום ${user.firstName}` : 'שלום אורח'}
                <UserOutlined />
              </Button>
            ) : (
              <Flex align="center" gap={20}>
                <Button
                  shape="round"
                  onClick={handleUserClick}
                  style={{
                    color: 'white',
                    background: 'unset',
                  }}
                >
                  {user?.firstName ? `שלום ${user.firstName}` : 'שלום אורח'}
                  <UserOutlined />
                </Button>
                <Button
                  shape="circle"
                  onClick={() => logOut()}
                  icon={<LogoutOutlined />}
                  style={{
                    color: 'white',
                    background: 'unset',
                  }}
                />
              </Flex>
            )}
          </Flex>
          <Flex
            justify="center"
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%,-50%)',
              height: '25vh',
              width: isMobileView && '100%',
              pointerEvents: 'none',
            }}
          >
            <Row>
              <Flex vertical gap={!isMobileView && 20} align="center">
                <img
                  src="/images/champions-league-logo.svg"
                  alt="logo"
                  width="100%"
                  style={{ maxWidth: isMobileView ? 80 : 120 }}
                />
                <Flex vertical align="center">
                  <Flex vertical align="center">
                    <Typography.Title level={isMobileView ? 4 : 2} style={{ color: 'white', margin: 'auto' }}>
                      משחקי ליגת האלופות
                    </Typography.Title>
                    <Typography.Title
                      level={isMobileView ? 4 : 2}
                      style={{ color: 'white', marginBottom: isMobileView ? '2px' : '16px' }}
                    >
                      לצפייה ישירה
                    </Typography.Title>
                  </Flex>
                  <Typography.Text style={{ fontSize: isMobileView && '14px', color: 'white' }}>
                    השידורים מועברים ללא שדר בעברית ולצפייה בישראל בלבד
                  </Typography.Text>
                  <Typography.Text style={{ fontSize: isMobileView && '14px', color: '#F0B826' }}>
                    *עלות הרכישה למשחק הינה 14 ₪
                  </Typography.Text>
                </Flex>
              </Flex>
            </Row>
          </Flex>
        </Header>
        {isMobileView && isVerified && (
          <MobileUserMenu isOpen={isDrawerOpen} toggleDrawer={toggleDrawer} championsLayout />
        )}
      </Affix>
      <Layout>
        <Content style={{ minHeight: 'calc(100vh - 64px)' }}>
          <Outlet />
        </Content>
      </Layout>
      <Layout>
        <Footer className="bg-champions-lobby-footer" />
      </Layout>
    </Layout>
  )
}
