import dayjs from 'dayjs'
import { isEmpty } from 'lodash'

export const CHAMPIONS_2024_SUBSCRIPTION_KEY = 'CHAMPIONS_2024_STREAM'
export const days = ['יום א׳', 'יום ב׳', 'יום ג׳', 'יום ד׳', 'יום ה׳', 'יום ו׳', 'יום ש׳']
export const dateFormat = 'DD.MM'
export const fullDateFormat = 'DD.MM.YYYY'
export const hourFormat = 'HH:mm'

export const layoutStyles = {
  header: {
    height: '10vh',
    padding: 0,
    borderRadius: '3px',
    boxShadow: '0px 1px 26.2px 0px #F0F6FD',
  },
  content: {
    height: 'calc(75vh - 64px)',
    overflowY: 'auto',
  },
  footer: {
    alignContent: 'center',
    justifyContent: 'center',
  },
}

export const getMatchDay = (date) => {
  const matchDate = new Date(date)
  const matchDay = days[matchDate.getDay()]
  const formattedDate = dayjs(matchDate).format(dateFormat)
  return `${matchDay} ${formattedDate}`
}

export const isThirtyMinutesBefore = (date) => {
  const matchDate = dayjs(date)
  const now = dayjs()
  // Check if we are within 30 minutes before the matchDate
  const isWithin30MinutesBefore = now.isBefore(matchDate) && matchDate.diff(now, 'minutes') <= 30

  // Check if matchDate has passed but within the last 3 hours
  const isWithinLast3Hours = now.isAfter(matchDate) && now.diff(matchDate, 'hours') <= 3
  return isWithin30MinutesBefore || isWithinLast3Hours
}

export const getMatchHour = (date) => {
  return dayjs(date).format(hourFormat)
}

export const getRemainingTimeToMatch = (date) => {
  const matchDate = dayjs(date)
  const now = dayjs()
  const diffInSeconds = matchDate.diff(now, 'seconds')
  return diffInSeconds > 0 ? diffInSeconds : 0
}

export const filteredMatchesBySelectedTeams = (data, selectedTeamsIds) => {
  return Object.entries(data).reduce((acc, [key, value]) => {
    const gamesPerDay = value.filter(
      (match) => selectedTeamsIds.includes(match.homeTeam._id) || selectedTeamsIds.includes(match.awayTeam._id),
    )
    if (!isEmpty(gamesPerDay)) {
      acc[key] = gamesPerDay
    }
    return acc
  }, {})
}

export const formatSecondsToTime = (seconds) => {
  // Calculate hours, minutes, and seconds
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)
  const remainingSeconds = seconds % 60

  // Pad with leading zeros if needed and format as HH:MM:SS
  return [
    String(hours).padStart(2, '0'),
    String(minutes).padStart(2, '0'),
    String(remainingSeconds).padStart(2, '0'),
  ].join(':')
}
