import { Form, Input, Button, Select, DatePicker, Flex } from 'antd'
import dayjs from 'dayjs'
const dateFormat = 'DD-MM-YYYY HH:mm'

export const GameForm = ({ onSubmit, options, stadiumsOptions, initialValues = {}, isLoading }) => {
  const [form] = Form.useForm()

  return (
    <Form layout="vertical" form={form} onFinish={onSubmit} initialValues={initialValues}>
      <Form.Item hidden name="_id">
        <Input />
      </Form.Item>
      <Flex justify="space-between">
        <Form.Item style={{ width: '45%' }} rules={[{ required: true }]} name="homeTeam" label={'קבוצה מארחת'}>
          <Select placeholder="בחר קבוצה" options={options} optionFilterProp="label" showSearch />
        </Form.Item>
        <Form.Item style={{ width: '45%' }} rules={[{ required: true }]} name="awayTeam" label={'קבוצה אורחת'}>
          <Select placeholder="בחר קבוצה" options={options} optionFilterProp="label" showSearch />
        </Form.Item>
      </Flex>
      <Form.Item style={{ width: '45%' }} rules={[{ required: true }]} name="matchDate" label={'תאריך ושעת המשחק'}>
        <DatePicker
          style={{ minHeight: '47px' }}
          format={dateFormat}
          showTime
          minDate={dayjs(new Date(), dateFormat)}
          disabledDate={(current) => current && current < dayjs()}
        />
      </Form.Item>
      <Flex justify="space-between">
        <Form.Item style={{ width: '45%' }} rules={[{ required: true }]} name="stadium" label={'מיקום'}>
          <Select placeholder="בחר איצטדיון" options={stadiumsOptions} optionFilterProp="label" showSearch />
        </Form.Item>
        <Form.Item style={{ width: '45%' }} name="stream" label={'קישור לסטרים'}>
          <Input style={{ minHeight: '47px' }} />
        </Form.Item>
      </Flex>
      <Form.Item style={{ width: '45%' }} name="price" label={'מחיר'}>
        <Input disabled defaultValue={45} style={{ minHeight: '47px' }} />
      </Form.Item>

      <Button block loading={isLoading} style={{ marginTop: 24 }} type="primary" htmlType="submit">
        {'שמירה'}
      </Button>
    </Form>
  )
}
