import { useMemo, useState } from 'react'
import { debounce, uniq } from 'lodash'
import { Table, Input, Button, Modal, message, Tag, Select, Flex, Avatar } from 'antd'
import TableActions from '../../components/elements/TableActions'
import { useTeams } from '../../hooks/useTeams'
import { TeamForm } from './components/TeamForm'
import { basketballKey, israeliTeamKey, nbaKey, worldTeamKey } from '../../utils/MyTeams.const'

const selectOptions = [
  { value: '', label: 'כל הקבוצות' },
  { value: israeliTeamKey, label: israeliTeamKey },
  { value: worldTeamKey, label: worldTeamKey },
  { value: basketballKey, label: basketballKey },
  { value: nbaKey, label: nbaKey },
]

const categoriesArray = [israeliTeamKey, worldTeamKey, basketballKey, nbaKey]

export default function Teams() {
  const [search, setSearch] = useState('')
  const [selectedCategory, setSelectedCategory] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [isFormModalOpen, setFormModal] = useState(false)
  const [formValues, setFormValues] = useState({})

  const { data = [], isLoading: isTeamLoading, createTeam, deleteTeam, updateTeam } = useTeams()

  const filteredData = useMemo(
    () =>
      data?.filter((team) => {
        if (selectedCategory === '') return team.name.includes(search)
        return team.sportCategories.some((category) => category.name === selectedCategory) && team.name.includes(search)
      }),
    [data, search, selectedCategory],
  )

  const categories = useMemo(() => {
    return data?.reduce((acc, team) => {
      const teamCategory = team.sportCategories.filter((category) => categoriesArray.includes(category.name))
      teamCategory.forEach((category) => (acc[category.name] = category._id))
      return acc
    }, {})
  }, [data])

  const onSearchChange = debounce((event) => {
    const { value = '' } = event.target
    setSearch(value.trim())
  }, 200)

  const onSelectChange = (value) => {
    setSelectedCategory(value)
  }

  const onFormModalClose = () => {
    setFormValues()
    setFormModal(false)
  }

  const onFormModalOpen = (values) => {
    if (values) setFormValues({ ...values, sportCategories: values.sportCategories?.[0].name })
    setFormModal(true)
  }

  const onSubmit = async (values) => {
    try {
      setLoading(true)
      const action = formValues?._id ? updateTeam : createTeam

      const newValues = {
        ...values,
        tags: uniq(values.tags),
        sportCategories: [categories[values.sportCategories]],
      }

      await action(newValues)
      onFormModalClose()
    } catch (error) {
      message.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  const onDeleteTeam = async ({ _id, name }) => {
    try {
      setLoading(true)

      Modal.confirm({
        title: 'מחיקה',
        content: `האם למחוק את הקבוצה "${name}"?`,
        okText: 'אישור',
        async onOk() {
          await deleteTeam(_id)
        },
      })
    } catch (error) {
      message.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div>
      <Table
        dataSource={filteredData}
        loading={isTeamLoading}
        rowKey="_id"
        bordered
        pagination={{
          pageSize: 10,
          pageSizeOptions: [10],
        }}
        columns={[
          {
            key: 'logo',
            title: 'לוגו',
            width: 80,
            dataIndex: 'iconUrl',
            render: (icon) => <Avatar src={icon} shape="square" size="large" />,
          },
          {
            title: 'שם הקבוצה',
            width: 150,
            dataIndex: 'name',
          },
          {
            title: 'קטגוריה',
            dataIndex: 'sportCategories',
            render: (sportCategories) =>
              sportCategories?.map((sportCategory) => <Tag key={sportCategory.name}>{sportCategory.name}</Tag>),
          },
          {
            title: 'מספרי תיקיות',
            width: 150,
            dataIndex: 'folderIds',
            render: (folderIds) => folderIds?.map((folderId) => <Tag key={folderId}>{folderId}</Tag>),
          },
          {
            title: 'תגיות',
            dataIndex: 'tags',
            render: (tags) => tags?.map((tag) => <Tag key={tag}>{tag}</Tag>),
          },
          {
            width: 80,
            render: (record) => {
              return (
                <TableActions
                  onDeleteClick={() => onDeleteTeam(record)}
                  onUpdateClick={() => onFormModalOpen(record)}
                />
              )
            },
          },
        ]}
        title={() => (
          <Flex justify="space-between" align="middle">
            <Flex gap={14}>
              <Input.Search onChange={onSearchChange} placeholder={'חיפוש'} allowClear />
              <Select
                style={{ width: 180, minWidth: 180 }}
                onChange={onSelectChange}
                options={selectOptions}
                value={selectedCategory}
              />
            </Flex>
            <Flex>
              <Button type="primary" onClick={() => onFormModalOpen()}>
                {'הוספת קבוצה'}
              </Button>
              <Modal title={' '} open={isFormModalOpen} footer={null} destroyOnClose onCancel={onFormModalClose}>
                <TeamForm onSubmit={onSubmit} initialValues={formValues} isLoading={isLoading} />
              </Modal>
            </Flex>
          </Flex>
        )}
      />
    </div>
  )
}
