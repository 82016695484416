import { useState } from 'react'
import { Table, Modal, message, Flex } from 'antd'
import TableActions from '../../../../components/elements/TableActions'
import { useStadiums } from '../../../../hooks/useStadiums'
import { STADIUM, stadiumColumns } from '../ManageGames.utils'
import { StadiumForm } from './StadiumForm'

export default function StadiumsTable({ formValues, isFormModalOpen, onFormModalClose, onFormModalOpen }) {
  const [isLoading, setLoading] = useState(false)

  const { data = [], isLoading: isStadiumLoading, createStadium, deleteStadium, updateStadium } = useStadiums()

  const onSubmit = async (values) => {
    try {
      setLoading(true)
      const action = formValues?._id ? updateStadium : createStadium

      await action(values)
      onFormModalClose(STADIUM)
    } catch (error) {
      message.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  const onDeleteStadium = async ({ _id, name }) => {
    try {
      setLoading(true)
      Modal.confirm({
        title: 'מחיקה',
        content: `האם למחוק את האיצטדיון "${name}"?`,
        okText: 'אישור',
        async onOk() {
          await deleteStadium(_id)
        },
      })
    } catch (error) {
      message.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div>
      <Table
        dataSource={data}
        loading={isStadiumLoading}
        rowKey="_id"
        bordered
        pagination={false}
        scroll={{ y: 220 }}
        onRow={() => ({
          style: { height: 75 },
        })}
        columns={[
          ...stadiumColumns,
          {
            width: 60,
            render: (record) => {
              return (
                <TableActions
                  onDeleteClick={() => onDeleteStadium(record)}
                  onUpdateClick={() => onFormModalOpen(STADIUM, record)}
                />
              )
            },
          },
        ]}
        title={() => (
          <Flex justify="space-between" align="middle">
            <Modal
              title={' '}
              open={isFormModalOpen}
              footer={null}
              destroyOnClose
              onCancel={() => onFormModalClose(STADIUM)}
            >
              <StadiumForm onSubmit={onSubmit} initialValues={formValues} isLoading={isLoading} />
            </Modal>
          </Flex>
        )}
      />
    </div>
  )
}
