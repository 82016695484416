import { Layout } from 'antd'
import Loader from '../components/layouts/Loader'

const { Content } = Layout
export default function LoaderPage() {
  return (
    <Layout>
      <Content style={{ minHeight: 'calc(100vh - 64px)' }}>
        <Loader />
      </Content>
    </Layout>
  )
}
