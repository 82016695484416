import { useState } from 'react'

import GamesTable from './components/GamesTable'
import StadiumsTable from './components/StadiumsTable'
import TeamsTable from './components/TeamsTable'
import { useLeagues } from '../../../hooks/useLeagues'
import Loader from '../../../components/layouts/Loader'
import { GAMES } from './ManageGames.utils'
import dayjs from 'dayjs'
import { Flex } from 'antd'

export default function ManageGames() {
  const [isGamesFormModalOpen, setGamesFormModal] = useState(false)
  const [isTeamsFormModalOpen, setTeamsFormModal] = useState(false)
  const [isStadiumFormModalOpen, setStadiumFormModal] = useState(false)

  const [formValues, setFormValues] = useState({})
  const { leagueId, isLoading } = useLeagues()

  const handleFormModal = (key) => {
    switch (key) {
      case 'games':
        setGamesFormModal((prevState) => !prevState)
        break
      case 'teams':
        setTeamsFormModal((prevState) => !prevState)
        break
      case 'stadium':
        setStadiumFormModal((prevState) => !prevState)
        break

      default:
        setTeamsFormModal((prevState) => !prevState)
        break
    }
  }

  const onFormModalClose = (key) => {
    setFormValues()
    handleFormModal(key)
  }

  const onFormModalOpen = (key, values) => {
    if (values) {
      if (key === GAMES) {
        const gameValues = {
          ...values,
          homeTeam: values.homeTeam._id,
          awayTeam: values.awayTeam._id,
          stadium: values.stadium._id,
        }
        setFormValues({ ...gameValues, matchDate: dayjs(values.matchDate) })
      } else setFormValues({ ...values })
    }
    handleFormModal(key)
  }
  if (isLoading || !leagueId) return <Loader />

  return (
    <div>
      <Flex style={{ height: '700px' }}>
        <GamesTable
          formValues={formValues}
          isFormModalOpen={isGamesFormModalOpen}
          onFormModalClose={onFormModalClose}
          onFormModalOpen={onFormModalOpen}
        />
      </Flex>
      <Flex justify="space-between" gap={20}>
        <TeamsTable
          formValues={formValues}
          isFormModalOpen={isTeamsFormModalOpen}
          onFormModalClose={onFormModalClose}
          onFormModalOpen={onFormModalOpen}
        />
        <StadiumsTable
          formValues={formValues}
          isFormModalOpen={isStadiumFormModalOpen}
          onFormModalClose={onFormModalClose}
          onFormModalOpen={onFormModalOpen}
        />
      </Flex>
    </div>
  )
}
