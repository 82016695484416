import { Button, Flex } from 'antd'
import { HelmetHeader } from '../../components/elements/HelmetHeader'
import { useProductSubscription } from '../../hooks/useProductSubscription'
import { useMemo, useState } from 'react'
import { CHAMPIONS_2024_SUBSCRIPTION_KEY } from './utils'
import { useResponsive } from '../../hooks/useResponsive'
import { useNavigate, useParams } from 'react-router-dom'
import { Video } from '../../components/elements/Video'
import { useGames } from '../../hooks/useGames'
import { useLeagues } from '../../hooks/useLeagues'
import Loader from '../../components/layouts/Loader'
import { isEmpty } from 'lodash'
import ChampionsWaitingRoom from './ChampionsWaitingRoom'
import ChampionsLeagueLiveLayout from '../../components/layouts/ChampionsLeagueLiveLayout'

export default function ChampionsLiveStream() {
  const { data: userSubscriptions } = useProductSubscription()
  const { isMobileView } = useResponsive()
  const { leagueId } = useLeagues()
  const navigate = useNavigate()
  const { id } = useParams()
  const { gameData, isGameLoading } = useGames(leagueId, id)
  const [enableButton, setEnableButton] = useState(false)
  const [watchLive, setWatchLive] = useState(false)

  //TODO finish validations after purchase

  const gameSubscriptions = useMemo(() => {
    return userSubscriptions?.reduce((acc, subscription) => {
      if (subscription.key === CHAMPIONS_2024_SUBSCRIPTION_KEY) {
        acc.push(subscription.productId)
      }
      return acc
    }, [])
  }, [userSubscriptions])

  if (isGameLoading) return <Loader />
  if ((isEmpty(gameData) && !isGameLoading) || !gameSubscriptions?.includes(id)) navigate('/champions-league')

  return (
    <ChampionsLeagueLiveLayout>
      <HelmetHeader />
      <Flex vertical gap={36} justify="center" align="center" style={{ marginTop: 55 }}>
        <img src="/images/champions-league-logo.svg" alt="logo" width="100%" style={{ maxWidth: 120 }} />
        {watchLive ? (
          <Video subscription={gameSubscriptions} streamUrl={gameData.stream} height="530px" width="950px" />
        ) : (
          <>
            <ChampionsWaitingRoom currentMatch={gameData} setEnableButton={setEnableButton} />
            <Button
              type="primary"
              disabled={!enableButton}
              onClick={() => setWatchLive(true)}
              style={{
                color: '#00004B',
                background: !enableButton ? '#8A94D3' : 'white',
                opacity: !enableButton ? 0.5 : 1,
              }}
            >
              {'לצפייה'}
            </Button>
          </>
        )}
      </Flex>
    </ChampionsLeagueLiveLayout>
  )
}
