import React from 'react'
import { Link, Outlet, useNavigate } from 'react-router-dom'
import { Layout, Flex, Affix, Button } from 'antd'
import { LogoutOutlined, UserOutlined } from '@ant-design/icons'
import { useUser } from '../../hooks/useUser'
import { useResponsive } from '../../hooks/useResponsive'

const { Header, Content } = Layout

export default function ChampionsLeagueLiveLayout({ children }) {
  const { user } = useUser()
  const navigate = useNavigate()
  return (
    <Layout>
      <Affix offsetTop={0}>
        <ChampionsLeagueHeaderLayout user={user} navigate={navigate} />
      </Affix>
      <Layout>
        <Content className="bg-champions-live" style={{ minHeight: 'calc(100vh - 64px)' }}>
          {children}
        </Content>
      </Layout>
    </Layout>
  )
}

export const ChampionsLeagueHeaderLayout = ({ user, navigate }) => {
  const { isMobileView } = useResponsive()
  return (
    <Header style={{ height: '90px' }}>
      <Flex justify="space-between" style={{ height: '90px' }}>
        <Link to="/" style={{ alignContent: 'center' }}>
          <img
            src="/images/logo.png"
            alt="logo"
            width="100%"
            onClick={() => navigate('/')}
            style={{ maxWidth: 50, display: 'block', cursor: 'pointer' }}
          />
        </Link>
        <Flex align="center" gap={20}>
          <Button
            shape="round"
            style={{
              color: 'blue',
              background: 'unset',
              padding: `${isMobileView && '10px'}`,
            }}
          >
            {user?.firstName ? `שלום ${user.firstName}` : 'שלום אורח'}
            <UserOutlined />
          </Button>
          <Button
            shape="circle"
            icon={<LogoutOutlined />}
            style={{
              color: 'blue',
              background: 'unset',
            }}
          />
        </Flex>
      </Flex>
    </Header>
  )
}
