import { useState } from 'react'
import { Table, Modal, message, Flex } from 'antd'
import TableActions from '../../../../components/elements/TableActions'
import { useLeagues } from '../../../../hooks/useLeagues'
import { TEAMS, teamsColumns } from '../ManageGames.utils'
import { ChampionsTeamForm } from './ChampionsTeamForm'
import { uniq } from 'lodash'

export default function TeamsTable({ formValues, isFormModalOpen, onFormModalClose }) {
  const [isLoading, setLoading] = useState(false)

  const { data: league = {}, isLoading: isLeagueLoading, updateLeague } = useLeagues()

  const onSubmit = async (values) => {
    try {
      setLoading(true)
      const teamsIds = league.teams.map((team) => team._id)
      const teams = [...teamsIds, ...values.teams]
      const updatedTeams = uniq(teams)
      const newData = {
        ...league,
        teams: updatedTeams,
      }
      await updateLeague(newData)
      onFormModalClose(TEAMS)
    } catch (error) {
      message.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  const onDeleteTeam = async ({ _id, name }) => {
    try {
      setLoading(true)

      Modal.confirm({
        title: 'מחיקה',
        content: `האם למחוק את הקבוצה "${name}"?`,
        okText: 'אישור',
        async onOk() {
          const updatedTeamsIds = league.teams.reduce((acc, team) => {
            if (team._id !== _id) acc.push(team._id)
            return acc
          }, [])

          const newData = {
            ...league,
            teams: updatedTeamsIds,
          }
          await updateLeague(newData)
        },
      })
    } catch (error) {
      message.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div>
      <Table
        dataSource={league.teams}
        loading={isLeagueLoading}
        rowKey="_id"
        bordered
        scroll={{ y: 220 }}
        pagination={false}
        onRow={() => ({
          style: { height: 75 },
        })}
        columns={[
          ...teamsColumns,
          {
            width: 25,
            render: (record) => <TableActions onDeleteClick={() => onDeleteTeam(record)} />,
          },
        ]}
        title={() => (
          <Flex justify="space-between" align="middle">
            <Modal
              title={' '}
              open={isFormModalOpen}
              footer={null}
              destroyOnClose
              onCancel={() => onFormModalClose(TEAMS)}
            >
              <ChampionsTeamForm onSubmit={onSubmit} initialValues={formValues} isLoading={isLoading} />
            </Modal>
          </Flex>
        )}
      />
    </div>
  )
}
