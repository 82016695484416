import { Col, Typography, Card, Flex, Button } from 'antd'
import { CheckOutlined, EditOutlined } from '@ant-design/icons'
import { useResponsive } from '../../hooks/useResponsive'

export const CreditCard = ({ cc, index, handleClick, editable = false }) => {
  const { ccNumber, expDate, isDefault = false } = cc
  const { isMobileView } = useResponsive()

  return (
    <Col flex="120px">
      <Card
        title=""
        bordered={false}
        styles={{
          body: {
            padding: '24px 32px',
            height: '120px',
            width: isMobileView ? '100%' : '460px',
            minWidth: '335px',
            border: isDefault && '2px solid #6774C7',
            borderRadius: isDefault && '12px',
          },
        }}
      >
        {isDefault && (
          <Flex
            style={{
              width: '24px',
              height: '24px',
              position: 'absolute',
              backgroundColor: '#6774C7',
              transform: 'translateY(-50%)',
              top: '50%',
              right: '-12px',
              borderRadius: '50%',
              color: 'white',
            }}
            align="center"
            justify="center"
          >
            <CheckOutlined />
          </Flex>
        )}
        <Flex vertical gap={16}>
          <Flex justify="space-between" align="center" style={{ height: '22px' }}>
            <Typography.Text style={{ fontSize: 16 }}>
              {isDefault ? 'הכרטיס הקבוע שלך (ברירת מחדל)' : `כרטיס ${index + 1}`}
            </Typography.Text>
            {editable && <Button type="link" icon={<EditOutlined />} onClick={() => handleClick(cc)} />}
          </Flex>
          <Flex>
            <Flex gap={25}>
              <Flex gap={5}>
                <Typography.Title level={5}>{ccNumber}</Typography.Title>
                <Typography.Title level={5}>{'****'}</Typography.Title>
                <Typography.Title level={5}>{'****'}</Typography.Title>
                <Typography.Title level={5}>{'****'}</Typography.Title>
              </Flex>
              <Typography.Title level={5}>{expDate}</Typography.Title>
            </Flex>
          </Flex>
        </Flex>
      </Card>
    </Col>
  )
}
