import { Button, Card, Flex, Layout, Typography } from 'antd'
import { useUser } from '../../hooks/useUser'
import { Link, useNavigate } from 'react-router-dom'

import { ChampionsLeagueHeaderLayout } from '../../components/layouts/ChampionsLeagueLiveLayout'
import { useResponsive } from '../../hooks/useResponsive'
import dayjs from 'dayjs'
import { fullDateFormat } from './ChampionsLeague.helpers'

export default function ChampionsLeagueSuccess() {
  const { user } = useUser()
  const navigate = useNavigate()
  const price = new Intl.NumberFormat('he-IL', { style: 'currency', currency: 'ILS', maximumFractionDigits: 0 }).format(
    14,
  )
  const { isMobileView } = useResponsive()

  return (
    <Layout>
      <ChampionsLeagueHeaderLayout user={user} navigate={navigate} />
      <Layout.Header
        className="bg-champions-payment-success"
        style={{ height: `${isMobileView ? '200px' : '340px'}` }}
      />
      <Layout.Content style={{ background: 'white' }}>
        <Flex vertical justify="center" align="center">
          <Typography.Title level={3} style={{ marginBottom: 10 }}>
            {'התשלום בוצע בהצלחה'}
          </Typography.Title>
          <Typography.Title level={5}>{'לינק לצפייה במשחק ישלח אליך במייל'}</Typography.Title>
          <Card
            title=""
            bordered={false}
            styles={{ body: { padding: '24px 32px', height: 150, width: `${isMobileView ? '300px' : '460px'}` } }}
          >
            <Flex gap={16} vertical>
              <Typography.Text>{'אישור תשלום'}</Typography.Text>
              <Flex justify="space-between">
                <Typography.Text strong>{'תאריך'}</Typography.Text>
                <Typography.Text>{`${dayjs().format(fullDateFormat)}`}</Typography.Text>
              </Flex>
              <Flex justify="space-between">
                <Typography.Text strong>{'סכום העסקה'}</Typography.Text>
                <Typography.Text>{price}</Typography.Text>
              </Flex>
            </Flex>
          </Card>
          <Link to={'/'}>
            <Button type="primary" style={{ borderRadius: '55px', padding: '25px 40px', marginTop: '40px' }}>
              {'חזרה לאזור האישי'}
            </Button>
          </Link>
        </Flex>
      </Layout.Content>
    </Layout>
  )
}
