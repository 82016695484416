import { useQuery } from 'react-query'
import axios from 'axios'

const endpoint = '/api/leagues'
const champions = 'champions2024'

export function useLeagues() {
  const { data: leagueData = {}, isLoading: isLeagueLoading } = useQuery({
    queryKey: [endpoint, champions],
    queryFn: () => axios.get(`${endpoint}/${champions}`).then((res) => res.data),
  })

  const {
    isLoading,
    data: response = {},
    refetch,
  } = useQuery({
    queryKey: [endpoint, leagueData?._id],
    queryFn: () => axios.get(`${endpoint}/teams/${leagueData._id}`),
    enabled: !!leagueData?._id,
  })

  const createLeague = async (values) => {
    await axios.post(endpoint, values)
    refetch()
  }

  const deleteLeague = async (id) => {
    await axios.delete(`${endpoint}/${id}`)
    refetch()
  }

  const updateLeague = async (values) => {
    await axios.put(`${endpoint}/${values._id}`, values)
    refetch()
  }

  const { data = [] } = response

  return {
    data,
    leagueId: leagueData?._id,
    isLoading,
    isLeagueLoading,
    createLeague,
    deleteLeague,
    updateLeague,
  }
}
