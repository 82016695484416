import { Col, Flex, Card, Typography } from 'antd'
import { useResponsive } from '../../../../hooks/useResponsive'

export default function EmptyCreditCard() {
  const { isMobileView } = useResponsive()
  return (
    <Col flex="120px">
      <Card
        title=""
        bordered={false}
        styles={{
          body: {
            padding: '24px 32px',
            height: '120px',
            width: isMobileView ? '100%' : '460px',
            minWidth: '335px',
            alignContent: 'center',
            border: '1px dashed #1F32AC',
            borderRadius: '12px',
            background: 'rgba(210, 214, 238, 0.20)',
          },
        }}
      >
        <Flex justify="center" align="center">
          <Typography.Text style={{ color: '#1F32AC' }}>{'+ הוסף כרטיס חדש'}</Typography.Text>
        </Flex>
      </Card>
    </Col>
  )
}
