import { useQuery } from 'react-query'
import axios from 'axios'
import { useUser } from './useUser'

async function getProductSubscriptions() {
  const { data } = await axios.get('/api/product-subscription')
  return data
}

export function useProductSubscription() {
  const { user } = useUser()

  return useQuery({
    queryKey: ['subscription', user?._id],
    queryFn: getProductSubscriptions,
    enabled: !!user,
  })
}
