import dayjs from 'dayjs'

export const CHAMPIONS_2024_SUBSCRIPTION_KEY = 'CHAMPIONS_2024_STREAM'

export const getMatchStatus = (date) => {
  const matchDate = dayjs(date)
  const now = dayjs(new Date())

  if (now.isAfter(matchDate.add(3, 'hours'))) {
    return 'נגמר'
  }

  return matchDate.isAfter(now) ? 'טרם התחיל' : 'התחיל'
}
