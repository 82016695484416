import queryString from 'query-string'

export function Video({ subscription, streamUrl, height = '347px', width = '616px' }) {
  const getStreamSource = queryString.stringifyUrl({
    url: 'https://watch.sport5.co.il',
    query: {
      platform: 'web',
      disableAds: true,
      src: queryString.stringifyUrl({
        url: streamUrl,
        query: {
          hdnea: subscription?.payload?.token,
        },
      }),
    },
  })

  return (
    <iframe
      title={streamUrl}
      src={getStreamSource}
      marginwidth="0"
      allowFullScreen
      loading="lazy"
      width="100%"
      height={height}
      class="video-iframe"
      marginheight="0"
      webkitallowfullscreen="true"
      style={{ border: 'none', maxWidth: width }}
      mozallowfullscreen="true"
    />
  )
}
