import { Button, Card, Col, Flex, Row, Typography } from 'antd'
import { HelmetHeader } from '../../components/elements/HelmetHeader'
import { useProductSubscription } from '../../hooks/useProductSubscription'
import { useMemo, useRef, useState } from 'react'
import { CHAMPIONS_2024_SUBSCRIPTION_KEY, getMatchStatus } from './utils'
import {
  filteredMatchesBySelectedTeams,
  getMatchDay,
  getMatchHour,
  isThirtyMinutesBefore,
} from './ChampionsLeague.helpers'
import { useResponsive } from '../../hooks/useResponsive'
import { GameCard } from '../../components/articles/GameCard'
import { isEmpty } from 'lodash'
import { FilterTwoTone } from '@ant-design/icons'
import { useGames } from '../../hooks/useGames'
import { useLeagues } from '../../hooks/useLeagues'
import VisibleDates from '../champions-league/components/VisibleDates'
import FilterClubsModal from '../champions-league/components/FilterClubsModal'
import Loader from '../../components/layouts/Loader'

export default function ChampionsLeagueLobby() {
  //TODO finish validations after purchase
  const [visibleDates, setVisibleDates] = useState([])
  const [selectedDate, setSelectedDate] = useState()
  const [filterModal, setFilterModal] = useState(false)
  const [filteredData, setFilteredData] = useState({})
  const [selectedTeamsIds, setSelectedTeamsIds] = useState([])

  const { data: userSubscriptions } = useProductSubscription()
  const { isMobileView } = useResponsive()
  const allDates = useRef(null)
  const filteredDates = useRef(null)
  const startIndex = useRef(0)
  const gamesRef = useRef({})
  const allTeamsRef = useRef({})
  const datesLength = !isEmpty(filteredDates.current) ? filteredDates.current.length : allDates.current?.length

  const gameSubscriptions = useMemo(() => {
    return userSubscriptions?.reduce((acc, subscription) => {
      if (subscription.key === CHAMPIONS_2024_SUBSCRIPTION_KEY) {
        acc.push(subscription.productId)
      }
      return acc
    }, [])
  }, [userSubscriptions])

  const { leagueId, data: league = {} } = useLeagues()
  const { data = [], isGamesLoading } = useGames(leagueId)

  const optionsData = useMemo(
    () => league.teams?.map((team) => ({ value: team._id, label: team.name, iconUrl: team.iconUrl })),
    [league.teams],
  )

  const modifiedData = useMemo(() => {
    const allTeams = {}
    const matchesByDate = data?.reduce((acc, match) => {
      const matchDay = getMatchDay(match.matchDate)
      const matchHour = getMatchHour(match.matchDate)
      const matchStatus = getMatchStatus(match.matchDate)
      if (matchStatus === 'נגמר') return acc
      const linkAvailable = isThirtyMinutesBefore(match.matchDate)
      const purchased = gameSubscriptions?.includes(match._id)
      const newMatch = {
        ...match,
        matchDay,
        matchHour,
        matchStatus,
        linkAvailable,
        purchased,
      }
      if (acc[matchDay]) acc[matchDay].push(newMatch)
      else acc[matchDay] = [newMatch]
      allTeams[match.homeTeam._id] = match.homeTeam
      allTeams[match.awayTeam._id] = match.awayTeam
      return acc
    }, {})
    if (!isEmpty(matchesByDate)) {
      const dates = Object.keys(matchesByDate)
      allDates.current = dates
      allTeamsRef.current = allTeams
      setVisibleDates(dates.slice(0, 7))
      setSelectedDate(dates[0])
      setFilteredData(matchesByDate)
    }
    return matchesByDate
  }, [data, gameSubscriptions])

  const handleDateClick = (selectedDate) => {
    const target = gamesRef.current[selectedDate]
    const stickyHeaderHeight = 80

    if (target) {
      window.scrollTo({ top: target.offsetTop - stickyHeaderHeight, behavior: 'smooth' })
    }
    setSelectedDate(selectedDate)
  }

  const handleFilter = (clearFilter = false) => {
    if (isEmpty(selectedTeamsIds)) {
      setFilteredData(modifiedData)
      setFilterModal(false)
      return
    }
    if (clearFilter) {
      filteredDates.current = null
      setSelectedTeamsIds([])
      setSelectedDate(allDates.current[0])
      setVisibleDates(allDates.current.slice(0, 7))
      return
    }

    const filteredMatches = filteredMatchesBySelectedTeams(modifiedData, selectedTeamsIds)
    setFilteredData(filteredMatches)
    const dates = Object.keys(filteredMatches)
    filteredDates.current = dates
    setVisibleDates(dates.slice(0, 7))
    setFilterModal(false)
  }

  const handleNextClick = () => {
    const nextIndex = startIndex.current + 1
    if (nextIndex < datesLength) {
      const isFiltering = filteredDates.current ?? allDates.current

      const currentDates = isFiltering.slice(nextIndex, nextIndex + 7)
      startIndex.current = nextIndex
      setVisibleDates(currentDates)
    }
  }

  const handlePrevClick = () => {
    const prevIndex = Math.max(startIndex.current - 1, 0)
    const isFiltering = filteredDates.current ?? allDates.current
    const currentDates = isFiltering.slice(prevIndex, prevIndex + 7)
    startIndex.current = prevIndex
    setVisibleDates(currentDates)
  }

  const handleSelect = (teamId) => {
    if (selectedTeamsIds.includes(teamId)) {
      const newTeams = selectedTeamsIds.filter((selectedTeamId) => teamId !== selectedTeamId)
      setSelectedTeamsIds(newTeams)
      return
    }
    setSelectedTeamsIds((prevState) => [...prevState, teamId])
  }

  if (isGamesLoading || isEmpty(league) || isEmpty(modifiedData)) return <Loader />

  return (
    <Row justify="center" className="bg bg4">
      <HelmetHeader />
      <Col flex="1300px">
        <VisibleDates
          visibleDates={visibleDates}
          selectedDate={selectedDate}
          allDates={!isEmpty(filteredDates.current) ? filteredDates.current : allDates.current}
          datesLength={datesLength}
          handleDateClick={handleDateClick}
          handleNextClick={handleNextClick}
          handlePrevClick={handlePrevClick}
        />
        <Flex
          vertical
          gap={isMobileView ? 46 : 56}
          style={{ padding: '10px 20px', margin: !isMobileView && '40px 24px 0px' }}
        >
          {isEmpty(filteredData) ? (
            <NoResults selectedDate={selectedDate} setFilterModal={setFilterModal} />
          ) : (
            <>
              {Object.keys(filteredData).map((key, idx) => (
                <Row
                  key={key}
                  justify="start"
                  ref={(el) => (gamesRef.current[key] = el)}
                  gutter={isMobileView ? [10, 10] : [24, 24]}
                >
                  <Col style={{ width: '100%' }}>
                    {idx === 0 ? (
                      <FilterClubButton title={key} setFilterModal={setFilterModal} />
                    ) : (
                      <Typography.Title level={2} style={{ margin: 0 }}>
                        {key}
                      </Typography.Title>
                    )}
                  </Col>
                  {modifiedData[key]?.map((match) => (
                    <Col key={match._id} flex={isMobileView && '558px'} style={isMobileView && { padding: 0 }}>
                      <GameCard key={match._id} match={match} />
                    </Col>
                  ))}
                </Row>
              ))}
            </>
          )}
        </Flex>
        <FilterClubsModal
          teamsOptions={optionsData}
          filterModal={filterModal}
          allTeams={league.teams}
          selectedTeamsIds={selectedTeamsIds}
          setFilterModal={setFilterModal}
          handleFilter={handleFilter}
          handleSelect={handleSelect}
        />
      </Col>
    </Row>
  )
}

const FilterClubButton = ({ title, setFilterModal }) => (
  <Flex justify="space-between">
    <Typography.Title level={2} style={{ margin: 0 }}>
      {title}
    </Typography.Title>
    <Button type="link" onClick={() => setFilterModal(true)}>
      <FilterTwoTone style={{ color: 'blue' }} />
      <Typography.Text style={{ color: 'blue', fontSize: '14px' }}>סינון לפי מועדון</Typography.Text>
    </Button>
  </Flex>
)

const NoResults = ({ selectedDate, setFilterModal }) => (
  <Col>
    <FilterClubButton title={selectedDate} setFilterModal={setFilterModal} />
    <Card
      title=""
      bordered={false}
      styles={{
        body: {
          padding: 0,
          height: 350,
          paddingRight: 32,
          display: 'flex',
          justifyContent: 'center',
        },
      }}
    >
      <Flex vertical align="center" justify="center">
        <img src="/images/champions-no-results.svg" alt="logo" width="100%" style={{ maxWidth: 175 }} />
        <Typography.Title level={4} style={{ marginBottom: 0 }}>
          {'לא נמצאו תוצאות'}
        </Typography.Title>
        <Typography.Text style={{ fontSize: '18px' }}>{'באפשרותך לבצע סינון מחדש'}</Typography.Text>
      </Flex>
    </Card>
  </Col>
)
