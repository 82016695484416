import { Col, Flex, Card, Typography, Button, Modal, message } from 'antd'

import { useState } from 'react'
import { CheckSquareFilled, DeleteOutlined } from '@ant-design/icons'

export default function SelectedCreditCard({ cc }) {
  const { ccNumber, expDate, isDefault = false } = cc
  const [isLoading, setIsLoading] = useState(false)
  const ccText = [ccNumber, '****', '****', '****']
  //TODO: add handle cc functions, for default, delete..
  const handleDefault = () => {}
  const handleDelete = () => {
    try {
      setIsLoading(true)

      Modal.confirm({
        title: 'מחיקת אשראי',
        content: 'האם אתה בטוח שתרצה להסיר את כרטיס האשראי?',
        okText: 'כן, בטוח',
        async onOk() {
          // await deleteGame(_id)
        },
      })
    } catch (error) {
      message.error(error.message)
    } finally {
      setIsLoading(false)
    }
  }
  return (
    <Flex vertical justify="center" align="center" gap={20} style={{ width: '100%' }}>
      <Col xs={24} style={{ alignSelf: 'self-start' }}>
        <Card style={{ background: 'unset' }} styles={{ body: { padding: '24px 24px 0px 0px' } }}>
          <Typography.Title level={2} style={{ marginBottom: 0 }}>
            {'הכרטיס שלך'}
          </Typography.Title>
        </Card>
      </Col>
      <Card
        title=""
        bordered={false}
        classNames={{ body: 'bg-champions-cc-cover' }}
        styles={{
          body: {
            padding: 40,
            height: '220px',
            width: '460px',
            borderRadius: '12px',
          },
        }}
      >
        <Flex vertical justify="space-between" style={{ height: '100%' }}>
          <Flex
            justify="space-between"
            style={{
              border: '1px solid white',
              background: '#283DC2',
              borderRadius: 5,
              padding: '10px 10px',
              boxShadow: '0px 2.713px 27.135px 0px rgba(1, 7, 45, 0.10)',
            }}
          >
            {ccText.map((text) => (
              <Typography.Text style={{ color: 'white', fontSize: 22 }}>{text}</Typography.Text>
            ))}
          </Flex>
          <Flex gap={60} style={{ alignSelf: 'flex-end' }}>
            <Flex vertical>
              <Typography.Text style={{ color: 'white', fontSize: 18, fontFamily: 'heebo' }}>
                {'Security Code'}
              </Typography.Text>
              <Typography.Text style={{ color: 'white', fontSize: 22, alignSelf: 'flex-end' }}>{'***'}</Typography.Text>
            </Flex>
            <Flex vertical>
              <Typography.Text style={{ color: 'white', fontSize: 18, fontFamily: 'heebo' }}>
                {'Expiration Date'}
              </Typography.Text>
              <Typography.Text style={{ color: 'white', fontSize: 22, alignSelf: 'flex-end' }}>
                {expDate}
              </Typography.Text>
            </Flex>
          </Flex>
        </Flex>
      </Card>
      <Flex vertical gap={20} justify="center" align="flex-start">
        <Button type="link" icon={<CheckSquareFilled />}>
          {'שמור כרטיס זה כאמצעי תשלום קבוע'}
        </Button>
        <Button type="link" icon={<DeleteOutlined />}>
          {'מחיקת אשראי'}
        </Button>
      </Flex>
      <Flex vertical justify="center" align="center" gap={8} style={{ width: '250px', marginTop: '250px' }}>
        <Flex
          style={{
            backgroundImage: `url(/images/safe-cc.png)`,
            backgroundRepeat: 'no-repeat',
            height: '48px',
            width: '44px',
            display: 'flex',
          }}
        ></Flex>
        <Typography.Text style={{ color: '#4C4C81', alignSelf: 'flex-end' }}>
          {'תהליך התשלום בכרטיס אשראי מאובטח והפרטים שלך נשארים חסויים'}
        </Typography.Text>
      </Flex>
    </Flex>
  )
}
