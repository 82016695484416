import { Form, Input, Button } from 'antd'

export const StadiumForm = ({ onSubmit, initialValues = {}, isLoading }) => {
  const [form] = Form.useForm()

  return (
    <Form layout="vertical" form={form} onFinish={onSubmit} initialValues={initialValues}>
      <Form.Item hidden name="_id">
        <Input />
      </Form.Item>
      <Form.Item rules={[{ required: true }]} name="name" label={'שם איצטדיון'}>
        <Input />
      </Form.Item>
      <Form.Item rules={[{ required: true }]} name="location" label={'הוספת מיקום'}>
        <Input />
      </Form.Item>

      <Button block loading={isLoading} style={{ marginTop: 24 }} type="primary" htmlType="submit">
        {'שמירה'}
      </Button>
    </Form>
  )
}
